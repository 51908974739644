<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    分销店铺
                </div>
            </template>
            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                <el-tab-pane label="全部" name="first">
                    <el-scrollbar height="600px">
                        <div class="content">
                            <span style="font-size:14px;margin-right:10px;">分销商名称:</span>
                            <el-input v-model="keyword" placeholder="请输入分销商名称" prefix-icon="el-icon-search"
                                class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                            <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down"
                                size="small">搜索</el-button>
                        </div>
                        <div class="content-main">
                            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0" v-loading="load"
                                element-loading-spinner="el-icon-loading" element-loading-text="加载中..."
                                :default-sort="{ prop: 'AddDTime', order: 'descending' }" size="small">
                                <el-table-column label="分销店铺" prop="storename" align="center">
                                    <template #default="scope">
                                        <text>{{ scope.row.storename }}</text>
                                    </template>
                                </el-table-column>
                                <el-table-column label="城市" prop="city" align="center">
                                    <template #default="scope">
                                        <text>{{ scope.row.city }}</text>
                                    </template>
                                </el-table-column>
                                <el-table-column label="身份证正面" prop="front" align="center">
                                    <template #default="scope">
                                        <el-image style="width: 71px; height: 50px"
                                            :preview-src-list="[scope.row.front]" :src="scope.row.front" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="身份证背面" prop="back" align="center">
                                    <template #default="scope">
                                        <el-image style="width: 71px; height: 50px" :preview-src-list="[scope.row.back]"
                                            :src="scope.row.back" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="营业执照" prop="zhizhao" align="center">
                                    <template #default="scope">
                                        <el-image style="width: 71px; height: 50px"
                                            :preview-src-list="[scope.row.zhizhao]" :src="scope.row.zhizhao" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="签约照片" prop="qianyue" align="center">
                                    <template #default="scope">
                                        <el-image style="width: 71px; height: 50px"
                                            :preview-src-list="[scope.row.qianyue]" :src="scope.row.qianyue" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="店铺类型" prop="storetype" align="center">
                                    <template #default="scope">
                                        <text v-if="scope.row.storetype == 1">连锁({{ scope.row.num }})</text>
                                        <text v-if="scope.row.storetype == 2">店面</text>
                                        <text v-if="scope.row.storetype == 3">柜台</text>
                                    </template>
                                </el-table-column>
                                <el-table-column label="区域团队" prop="agentname" align="center">
                                    <template #default="scope">
                                        <text>{{ scope.row.agentname }}</text><br />
                                    </template>
                                </el-table-column>
                                <el-table-column label="商铺电话" prop="stroephone" align="center">
                                    <template #default="scope">
                                        <text>{{ scope.row.storephone }}</text><br />
                                    </template>
                                </el-table-column>
                                <el-table-column label="商家收款账户" prop="moneyname" align="center">
                                    <template #default="scope">
                                        <text>{{ scope.row.moneyname }}</text><br />
                                    </template>
                                </el-table-column>
                                <el-table-column label="备注" prop="remark" align="center">
                                    <template #default="scope">
                                        <text>{{ scope.row.remark }}</text><br />
                                    </template>
                                </el-table-column>
                                <el-table-column label="商家收款码" prop="moneyimage" align="center">
                                    <template #default="scope">
                                        <el-image style="width:71px;height:50px"
                                            :preview-src-list="[scope.row.moneyimage]" :src="scope.row.moneyimage" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center">
                                    <template #default="scope">
                                        <div style="display: flex;flex-direction: column;">
                                            <el-tag size="small" @click="remark(scope.row.id)"
                                                style="cursor: pointer;">填写备注</el-tag>
                                            <el-tag @click="edit(scope.row.id)" size="small"
                                                style="cursor: pointer;margin-top:10px">编辑</el-tag>
                                            <el-tag size="small" v-if="scope.row.accountid == 0"
                                                @click="createaccount(scope.row.id)"
                                                style="cursor: pointer;margin-top:10px">创建账号</el-tag>
                                            <el-tag size="small" @click="detele(scope.row.id)"
                                                style="cursor: pointer;margin-top:10px">删除</el-tag>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                                :current-page="curpage" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                                layout="sizes,total, prev, pager, next, jumper" :total="totalcount"
                                :hide-on-single-page="false" style="text-align:center"></el-pagination>
                        </div>
                    </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="城市管理" name="two">
                    <el-scrollbar height="600px">
                        <div class="content">
                            <span style="font-size:14px;margin-right:10px;">城市名称:</span>
                            <el-input v-model="cityKeyWord" placeholder="请输入城市名称" prefix-icon="el-icon-search"
                                class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                            <el-button type="primary" @click="getCity" style="margin-left:10px;"
                                class="hidden-sm-and-down" size="small">搜索</el-button>
                            <el-button type="primary" @click="addCity" style="margin-left:10px;"
                                class="hidden-sm-and-down" size="small">添加城市</el-button>
                        </div>
                        <div class="content-main">
                            <el-table :data="cityData" border style="width: 100%; margin: 1em 0" v-loading="load"
                                element-loading-spinner="el-icon-loading" element-loading-text="加载中..." size="small">
                                <el-table-column label="序号" type="index" align="center" />
                                <el-table-column label="城市名称" prop="city" align="center" />
                                <el-table-column label="状态" prop="status" align="center">
                                    <template #default="scope">
                                        <span v-if="scope.row.status == 1">
                                            启用
                                        </span>
                                        <span v-else>
                                            停用
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="添加时间" prop="time" align="center" />
                                <el-table-column label="操作" align="center">
                                    <template #default="scope">
                                        <el-tag size="small" type="danger" v-if="scope.row.status == 1"
                                            @click="changeStatus(scope.row.id, scope.row.status, scope.row)"
                                            style="cursor: pointer;">
                                            停用
                                        </el-tag>
                                        <el-tag size="small" v-if="scope.row.status == 0"
                                            @click="changeStatus(scope.row.id, scope.row.status, scope.row)"
                                            style="cursor: pointer;">
                                            启用
                                        </el-tag>
                                        <el-tag @click="editCity(scope.row.id, scope.row)" size="small"
                                            style="cursor: pointer;margin-left:10px;">编辑</el-tag>
                                        <el-tag size="small" type="danger"
                                            @click="deteleMessage(scope.row, scope.row.$index)"
                                            style="cursor: pointer;margin-left:10px;">删除
                                        </el-tag>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <el-pagination @current-change="handleCurrentChange"
                                            @size-change="handleSizeChange"
                                            :current-page="curpage"
                                            :page-size="pagesize"
                                            :page-sizes="[10,20,30,50,100]"
                                            layout="sizes,total, prev, pager, next, jumper"
                                            :total="totalcount"
                                            :hide-on-single-page="false"
                                            style="text-align:center"></el-pagination> -->
                        </div>
                    </el-scrollbar>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>
    <!--还款详情-->
    <el-dialog v-model="huankuan" title="还款详情" center width="55%" :before-close="handleClose">
        <el-table :data="huankuandetail" style="width: 100%">
            <el-table-column type="index" label="期数" :index="indexMethod" width="80" />
            <el-table-column prop="PayDate" label="预计扣款日" width="100" />
            <el-table-column prop="PayMoney" label="账单金额(元)" width="100" />
            <el-table-column prop="address" label="实还金额(元)" width="100" />
            <el-table-column prop="address" label="未还金额(元)" width="100" />
            <el-table-column prop="Status" label="还款情况">
                <template #default="scope">
                    <text v-if="scope.row.Status == 0">未扣款</text>
                    <text v-if="scope.row.Status == 1">已扣款</text>
                    <text v-if="scope.row.Status == 3">扣款失败</text>
                </template>
            </el-table-column>
            <el-table-column prop="UpdateTime" label="实际扣款日" width="160" />
            <el-table-column prop="" label="本期结清方式" width="150" />
            <el-table-column label="操作" width="180">
                <template #default="scope">
                    <el-button type="primary" v-if="scope.row.Status == 0" disable="false"
                        @click="handmoney(scope.row.ID, scope.row.PayMoney, scope.row)" size="small">手动扣款</el-button>
                    <el-tag class="ml-2" v-if="scope.row.Status == 2" type="info">扣款处理中...</el-tag>
                    <el-tag class="ml-2" type="success" v-if="scope.row.Status == 1">扣款成功</el-tag>
                    <el-tag class="ml-2" type="danger" v-if="scope.row.Status == 3">扣款失败<el-tag
                            @click="handmoney(scope.row.ID, scope.row.PayMoney, scope.row)">重新扣款</el-tag></el-tag>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="huankuan = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <!--还款详情-->
    <!--审核-->
    <el-dialog v-model="shenhe" title="审核" width="30%">
        <el-radio-group v-model="radio" @click="tongguo">
            <el-radio label="2">通过</el-radio>
            <el-radio label="3">不通过</el-radio>
        </el-radio-group>
        <el-input style="margin-top:10px" v-if="textarea" v-model="text" :rows="2" type="textarea"
            placeholder="请输入不通过的原因" />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="shenhe = false">关闭</el-button>
                <el-button type="primary" @click="shenheclick">确认</el-button>
            </span>
        </template>
    </el-dialog>
    <!--审核-->
    <!--添加城市-->
    <el-dialog v-model="aCity" title="添加城市" width="20%">
        <el-input v-model="city" placeholder="请输入城市名称" />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="aCity = false">关闭</el-button>
                <el-button type="primary" @click="saveCity">保存</el-button>
            </span>
        </template>
    </el-dialog>
    <!--添加城市-->
    <!--备注-->
    <el-dialog v-model="remarkmodel" title="备注" width="30%" align-center>
        <el-input v-model="remarktext" type="textarea" placeholder="请填写备注" />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="remarkmodel = false">关闭</el-button>
                <el-button type="primary" @click="remarksave">
                    保存
                </el-button>
            </span>
        </template>
    </el-dialog>
    <!--备注-->
</template>
<style scoped></style>
<script>

import constant from "@/constant"
import { ref } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    data() {
        return {
            visible: ref(false),
            city: "",
            aCity: false,
            cityKeyWord: "",
            tab_name: "first",
            cityData: [],
            remarkid: "",
            remarkmodel: ref(false),
            remarktext: ref(''),
            text: ref(""),
            radio: ref('2'),
            textarea: ref(false),
            shenhe: ref(false),
            id: "",
            mid: ref(""),
            row: ref(""),
            huankuandetail: ref(""),
            huankuan: ref(false),
            activeName: ref('first'),
            tabledata: [],
            keyword: '',
            curpage: '1',
            pagesize: '10',
            totalcount: "",
        }
    },
    methods: {
        editCity(id, row) {
            console.log(id, row)
            ElMessageBox.prompt('请输入城市名称', '编辑', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                this.axios.get(constant.editCity, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        id: id,
                        city: value,
                    }
                }).then((response) => {
                    console.log(response.data)
                    if (response.data == 200) {
                        this.$message.success('修改成功')
                        row.city = value
                    } else {
                        this.$message.error('修改失败')
                    }
                })
            })
        },
        changeStatus(id, status, row) {
            this.axios.get(constant.changeStatus, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: id,
                    status: status,
                }
            }).then((response) => {
                if (response.data == 200) {
                    if (status == 1) {
                        row.status = 0
                        this.$message.success('停用成功')
                    } else {
                        row.status = 1
                        this.$message.success('启用成功')
                    }
                } else {
                    this.$message.error('修改失败')
                }
            })
        },
        deteleMessage(row, rowindex) {
            ElMessageBox.confirm(
                `确认删除${row.city}?`,
                '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                this.deteleCity(row, rowindex)
            })
        },
        deteleCity(row, rowindex) {
            console.log(row.id)
            this.axios.get(constant.deteleCity, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: row.id,
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data == 200) {
                    this.$message.success('删除成功')
                    this.cityData.splice(rowindex, 1);
                } else {
                    this.$message.error('删除失败')
                }
            })
        },
        remark(id) {
            this.remarkid = id
            this.remarkmodel = true
        },
        remarksave() {
            this.axios.get(constant.remarksave, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: this.remarkid,
                    remark: this.remarktext
                }
            }).then((response) => {
                if (response.data.code == 200) {
                    this.$message.success('保存成功');
                    this.remarkmodel = false;
                } else {
                    this.$message.error('保存失败');
                }
            })
        },
        createaccount(id) {
            console.log(id)
            this.axios.get(constant.createaccount, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response)
                if (response.data.code == 200) {
                    this.$message.success('创建成功');
                    this.$router.go(0)
                } else {
                    this.$message.error("创建失败");
                }
            })
        },
        edit(id) {
            this.$router.push({ path: '/Editpartner', query: { id: id } })
        },
        detele(id) {
            console.log(id)
            ElMessageBox.confirm(
                '确认删除店铺?',
                '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                this.axios.get(constant.detelepartner, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response)
                    if (response.data.code == 200) {
                        this.$message.success("删除成功");
                        this.$router.go(0)
                    } else {
                        this.$message.error("删除失败");
                    }
                })
            })
        },
        shenheclick() {
            console.log(this.row)
            this.axios.get(constant.shenhe, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    radio: this.radio,
                    text: this.text,
                    item: JSON.stringify(this.row)
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.data == 1) {
                    this.shenhe = false
                    ElMessage({
                        message: "审核成功",
                        type: "success"
                    })

                }
            })
        },
        tongguo() {
            console.log(this.radio)
            if (this.radio == 3) {
                this.textarea = false
            } else {
                this.textarea = true
            }
        },
        DisableMerchant(id, row) {
            this.shenhe = true
            this.row = row
            this.mid = id
            console.log(id)
        },
        handmoney(ID, money, row) {
            console.log(money)
            console.log(ID, this.id, row)
            row.Status = 2
            this.axios.get(constant.handmoney, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: this.id,
                    money: money,
                    ID: ID
                }
            }).then((response) => {
                console.log(response)
                if (response.data.code == 10000) {
                    ElMessage({
                        message: '手动扣款成功(' + response.data.msg + ')',
                        type: 'success',
                    })
                    row.Status = 1
                } else {
                    ElMessage({
                        message: "扣款失败(" + response.msg + ")",
                        type: 'waring'
                    })
                    row.Status = 3
                }
            })
        },
        indexMethod(index) {
            return "第" + (index + 1) + "期"
        },
        handleClose() {
            this.huankuan = false
        },
        detail(id) {
            console.log(id)
            this.id = id
            this.huankuan = true
            this.axios.get(constant.huankuandetail, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data)
                this.huankuandetail = response.data
            })
        },
        handleClick(tab) {
            let name = tab.props.name
            this.tab_name = name
            if (name == 'first') {
                this.init();
            }
            if (name == 'two') {
                this.getCity()
            }
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        saveCity() {
            this.axios.get(constant.saveCity, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    city: this.city,
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data == 200) {
                    this.$message.success('添加成功')
                    this.getCity()
                } else {
                    this.$message.error('添加失败')
                }
                this.aCity = false
            })
        },
        addCity() {
            this.city = ''
            this.aCity = true
        },
        getCity() {
            this.cityData = []
            this.axios.get(constant.getCity, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    keyword: this.cityKeyWord,
                }
            }).then((response) => {
                console.log(response.data)
                this.cityData = response.data
            })
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val
            this.init();
        },
        init() {
            this.axios.get(constant.getpartner, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    keyword: this.keyword,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        }
    },
    created: function () {
        this.init();
    }
}
</script>
