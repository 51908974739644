<template>
  <div class="settle">
    <el-card>
      <template #header>
        <div class="clearfix" style="text-align:left">
          <span>打款明细</span>
        </div>
      </template>
      <div class="content-header">
        <el-row :gutter="5">
          <el-col :span="24" style="text-align:left;">
            <div class="searchblock">
              <el-select v-model="merchantid" filterable style="margin-right:10px;width:150px"
                         clearable placeholder="请选择商户" size="small" v-if="ismerchant != 1">
                <el-option v-for="item in merchants" :key="item" :value="item.ID"
                           :label="item.Merchant"></el-option>
              </el-select>
              <el-input v-model="keyword" placeholder="请输入流水号/订单号" prefix-icon="el-icon-search"
                        class="hidden-sm-and-down" style="width:200px" size="small"></el-input>
              <el-date-picker v-model="range" type="datetimerange" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" style="margin-left:10px;width:340px;" size="small" :default-time="defaulttime"></el-date-picker>
              <el-button type="primary" @click="init" style="margin-left:10px;"
                         class="hidden-sm-and-down" size="small">搜索</el-button>
              <el-button type="primary" style="margin-left:10px" class="hidden-sm-and-down" size="small">
                <download-excel class="btn btn-default" :fetch="fetchDataOperation"
                                :fields="json_fieldsOperation" :before-generate="startDownload"
                                :before-finish="finishDownload" type="csv" name="打款明细.csv">
                  <span style="padding: 11px 20px; margin: 11px -20px">导出</span>
                </download-excel>
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-row style="height: 40px;color: red;display: grid;align-items: center">
          <el-col :span="24">
          今日打款：{{ day }}元，本月打款：{{ month }}元，  打款总金额：{{ totalamount }}元
          </el-col>
        </el-row>
      </div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="全部" v-if="ismerchant != 1" name="all">
          <el-table :data="filterTableData" style="width: 100%">
            <el-table-column label="商户名" prop="merchant" />
            <el-table-column label="订单编号" prop="ordernum" />
            <el-table-column label="充值/打款金额(元)" prop="amount">
              <template #default="scope">
                <span v-if="scope.row.amount">
                  <span v-if="scope.row.type == 1" style="color:red;"> +{{ scope.row.amount
                    }}</span>
                  <span v-else>-{{ scope.row.amount }}</span>
                </span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="账户余额(元)" prop="account">
              <template #default="scope">
                <span v-if="scope.row.account">
                  <span>{{ scope.row.account }}</span>
                </span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="收货人姓名" prop="consignee" />
            <el-table-column v-if="ismerchant != 1" label="分销商" prop="partner" />
            <el-table-column label="交易流水号" prop="serialnum">
              <template #default="scope">
                <span v-if="scope.row.serialnum">{{ scope.row.serialnum }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="addtime">
              <template #default="scope">
                <span v-if="scope.row.addtime">{{ scope.row.addtime }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status">
              <template #default="scope">
                <span v-if="scope.row.status == 0">
                  未打款
                </span>
                <span v-if="scope.row.status == 1">已打款</span>
                <span v-if="scope.row.status == 2">充值</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="right" prop="status">
              <template #default="scope">
                <el-button size="small"
                           v-if="scope.row.status == 0 && scope.row.deal != 1 && ismerchant == 0"
                           @click="handle(scope.$index, scope.row)">手动打款</el-button>
                <el-button size="small" type="danger" style="margin-top:10px;"
                           v-if="scope.row.status == 0 && scope.row.deal != 1 && ismerchant == 0"
                           @click="handleEdit(scope.$index, scope.row)">修改打款</el-button>
                <el-button size="mini" v-if="scope.row.status == 1">
                  <span @click="look(scope.row)">查看</span>
                </el-button>
                <el-button style="margin-top:10px;" size="mini"
                           v-if="scope.row.type == 2 && ismerchant == 0">
                  <span
                        @click="backout(scope.row.id, scope.row.mid, scope.row.amount)">撤销打款</span>
                </el-button>
                <el-button style="margin-top:10px;" size="mini"
                           v-if="scope.row.type == 3">已撤销</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="ismerchant != 1" label="等待打款" name="wait">
          <el-table :data="filterTableData" style="width: 100%">
            <el-table-column label="商户名" prop="merchant" />
            <el-table-column label="订单编号" prop="ordernum" />
            <el-table-column label="充值/打款金额(元)" prop="amount">
              <template #default="scope">
                <span v-if="scope.row.amount">
                  <span v-if="scope.row.type == 1" style="color:red;"> +{{ scope.row.amount
                    }}</span>
                  <span v-else>-{{ scope.row.amount }}</span>
                </span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="账户余额" prop="account">
              <template #default="scope">
                <span v-if="scope.row.account">{{ scope.row.account }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="收货人姓名" prop="consignee" />
            <el-table-column label="分销商" prop="partner" />
            <el-table-column label="交易流水号" prop="serialnum">
              <template #default="scope">
                <span v-if="scope.row.serialnum">{{ scope.row.serialnum }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="addtime">
              <template #default="scope">
                <span v-if="scope.row.addtime">{{ scope.row.addtime }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status">
              <template #default="scope">
                <span v-if="scope.row.status == 0">
                  未打款
                </span>
                <span v-if="scope.row.status == 1">已打款</span>
                <span v-if="scope.row.status == 2">充值</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" v-if="ismerchant != 1" align="right" width="200px"
                             prop="status">
              <template #default="scope">
                <el-button size="small"
                           v-if="scope.row.status == 0 && scope.row.deal != 1 && ismerchant == 0"
                           @click="handle(scope.$index, scope.row)">手动打款</el-button>
                <el-button size="small" type="danger"
                           v-if="scope.row.status == 0 && scope.row.deal != 1 && ismerchant == 0"
                           @click="handleEdit(scope.$index, scope.row)">修改打款</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="finish">
          <el-table :data="filterTableData" style="width: 100%">
            <el-table-column label="商户名" prop="merchant" />
            <el-table-column label="订单编号" prop="ordernum" />
            <el-table-column label="充值/打款金额" prop="amount">
              <template #default="scope">
                <span v-if="scope.row.amount">{{ scope.row.amount }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="账户余额" prop="account">
              <template #default="scope">
                <span v-if="scope.row.account">{{ scope.row.account }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="收货人姓名" prop="consignee" />
            <el-table-column label="分销商" prop="partner" />
            <el-table-column label="交易流水号" prop="serialnum">
              <template #default="scope">
                <span v-if="scope.row.serialnum">{{ scope.row.serialnum }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="addtime">
              <template #default="scope">
                <span v-if="scope.row.addtime">{{ scope.row.addtime }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status">
              <template #default="scope">
                <span v-if="scope.row.status == 0">
                  未打款
                </span>
                <span v-if="scope.row.status == 1">已打款</span>
                <span v-if="scope.row.status == 2">充值</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="right" width="200px">
              <template #default="scope">
                <el-button v-if="scope.row.status == 1">
                  <span @click="look(scope.row)">查看</span>
                </el-button>
                <el-button style="margin-top:10px;" size="mini"
                           v-if="scope.row.status == 1 && ismerchant == 0">
                  <span
                        @click="backout(scope.row.id, scope.row.mid, scope.row.amount)">撤销打款</span>
                </el-button>
                <el-button style="margin-top:10px;" size="mini"
                           v-if="scope.row.status == 3">已撤销</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                     :current-page="curpage" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                     layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                     style="text-align:center">
      </el-pagination>
    </el-card>
  </div>
  <el-dialog v-model="dialogVisible1" title="打款详情" width="30%" :before-close="handleClose">
    <p>交易流水号：</p>
    <p>{{ array.serialnum }}</p>
    <p>交易截图</p>
    <p>
      <el-image style="width: 200px; height: 200px" :src="array.tradingpic" :zoom-rate="1.2"
                :preview-src-list="srcList" :initial-index="4" fit="cover" />
    </p>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible1 = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="addsettle" title="手动打款" width="30%" center>
    <el-form :model="settleinfo" ref="settleinfo" label-width="150px" :rules="rules">
      <el-form-item label="商户名称:" prop="merchantid">
        <span v-text="settleinfo.merchantid"></span>
      </el-form-item>
      <el-form-item label="收货人姓名:" prop="consignee">
        <span v-text="settleinfo.consignee"></span>
      </el-form-item>
      <el-form-item label="打款金额(元):" prop="amount">
        <span v-text="settleinfo.amount"></span>
      </el-form-item>
      <el-form-item label="收款账户:" prop="partner">
        <span v-text="settleinfo.partner"></span>
      </el-form-item>
      <el-form-item label="商家收款二维码:" prop="moneyimage">
        <el-image style="width: 100px; height: 100px" :src="settleinfo.moneyimage" :zoom-rate="1.2"
                  :preview-src-list="srcList" :initial-index="4" fit="cover" />
      </el-form-item>
      <el-form-item label="交易流水号:" prop="serialnum">
        <span v-for="(item, i) in serialnum" :key="i">
          <el-input v-model="item.index" style="width:250px;margin-right: 10px;"></el-input>
          <el-tag @click="delinput(i)" v-if="i != 0" style="cursor: pointer;">删除</el-tag>
          <el-tag @click="addinput" v-if="i == 0" style="cursor: pointer;">添加</el-tag>
        </span>
      </el-form-item>
      <el-form-item label="交易截图:" prop="tradingpic">
        <el-upload v-model:file-list="fileList" :on-success="uploadsuccess" :action="uploadFile"
                   list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
          上传文件
        </el-upload>

        <el-dialog v-model="dialogVisible">
          <img w-full :src="dialogImageUrl" alt="Preview Image" />
        </el-dialog>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="Save" type="primary">确定</el-button>
      <el-button size="small" @click="Cancel">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
// import qs from 'qs'
import Cookies from 'js-cookie'
import constant from '@/constant'
import { ref } from 'vue'
import JsonExcel from "vue-json-excel3";
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    components: {
        downloadExcel: JsonExcel,
    },
    data() {
        return {
            day:'',
            month:'',
            totalamount:'',
            json_fieldsOperation: {
                商户名: "merchant",
                订单编号: "ordernum",
                "充值/打款金额": "amount",
                账户余额: "account",
                收货人: "consignee",
                分销商: "partner",
                交易流水号: "serialnum",
                时间: "addtime",
                状态: {
                    field: "status",
                    callback: (value) => {
                        if (value == 0) {
                            return `未打款`;
                        }
                        if (value == 1) {
                            return `已打款`;
                        }
                        if (value == 2) {
                            return `充值`;
                        }
                        if (value == 3) {
                            return `退款`;
                        }
                    }
                }
            },
            array: [],
            dialogVisible1: ref(false),
            rechargeid: "",
            uploadFile: constant.uploadfile,
            status: "",
            filterTableData: [],
            activeName: ref('all'),
            tabledata: [],
            ismerchant: '',
            mid: '',
            addsettle: false,
            keyword: '',
            range: '',
            merchantid: '',
            curpage: 1,
            pagesize: 10,
            totalcount: '',
            type: '',
            serialnum: [
                {
                    index: ""
                }
            ],
            srcList: [],
            settleinfo: {
                merchantid: '',
                amount: '',
                partner: '',
                moneyimage: '',
                orderuid: '',
                remark: '',
            },
            fileList: [],
            dialogImageUrl: ref(''),
            dialogVisible: ref(false),
            rules: {
                orderuid: [{
                    required: true,
                    message: '请填写订单号',
                    trigger: 'blur'
                }],
                remark: [{
                    required: true,
                    message: '请填写备注',
                    trigger: 'blur'
                }]
            },
            item: [],
        }
    },
    methods: {
        async fetchDataOperation() {
            const response = await this.axios.get(constant.exportRecharge, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                  range: this.range,
                    status: this.status,
                    keyword: this.keyword,
                    merchantid: this.merchantid,
                },
            });
            if (response.data.length == 0) {
                ElMessage({
                    type: "warning",
                    message: "暂无数据可下载",
                    center: true,
                });
                return false;
            }
            return response.data;
        },
        startDownload() {
            ElMessage({
                type: "warning",
                message: "开始下载",
                center: true,
            });
        },
        finishDownload() {
            ElMessage({
                type: "success",
                message: "下载完成",
                center: true,
            });
        },
        backout(id, mid, amount) {
            console.log(id, mid, amount)
            ElMessageBox.confirm(
                '确定撤销打款?',
                '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.axios.get(constant.backout, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            mid: mid,
                            id: id,
                            amount: amount
                        }
                    }).then((response) => {
                        console.log(response.data)
                        if (response.data.code == 200) {
                            this.$message.success('撤销成功');
                            this.$router.go(0);
                        } else {
                            this.$message.error('撤销失败');
                        }
                    })
                }).catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消',
                    })
                })
        },
        look(id) {
            this.dialogVisible1 = true
            console.log(id)
            this.array = id
            this.srcList.push(id.tradingpic)
        },
        uploadsuccess(file) {
            console.log(file)
            this.fileList.push({ "url": file.url, "name": file.name })
        },
        handleRemove(uploadFile, uploadFiles) {
            console.log(uploadFile, uploadFiles)
        },
        handlePictureCardPreview(uploadFile) {
            console.log(uploadFile)
            this.dialogImageUrl = uploadFile.url
            this.dialogVisible = true
        },
        delinput(e) {
            console.log(e)
            this.serialnum.splice(e, 1);
            console.log(this.serialnum)
        },
        addinput() {
            this.serialnum.push({ "index": "" })
            console.log(this.serialnum)
        },
        handle(index, row) {
            this.fileList = []
            console.log(index, row)
            this.axios.get(constant.getaccount, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    mid: row.mid,
                    amount: row.amount
                }
            }).then((response) => {
                if (response.data.code == 200) {
                    this.$message.error("账户余额不足,充值后打款");
                } else {
                    this.addsettle = true
                }
            })
            this.item = row
            this.rechargeid = row.id
            console.log(this.item)
            this.settleinfo.merchantid = row.merchant
            this.settleinfo.amount = row.amount
            this.settleinfo.partner = row.moneyname
            this.settleinfo.moneyimage = row.moneyimage
            this.settleinfo.consignee = row.consignee
            this.srcList.push(row.moneyimage)
            console.log(this.serialnum[0])
        },
        handleEdit(index, row) {
            console.log(index, row)
            this.item = row
            this.$prompt('请输入打款金额', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^([1-9][0-9]*|0)(\.[0-9]?[1-9])?$/,
                inputErrorMessage: '请输入正确的金额格式'
            }).then(({ value }) => {
                this.axios.get(constant.changeamount, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        amount: value,
                        id: row.id
                    }
                }).then((response) => {
                    console.log(response.data)
                    if (response.data.code == 200) {
                        this.$message.success("修改成功");
                        this.item.amount = value
                    } else {
                        this.$message.error("修改失败");
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        handleClick(tab) {
            this.filterTableData = []
            this.totalcount = "";
            this.curpage = 1;
            console.log(tab.props.name)
            if (tab.props.name == 'all') {
                this.status = "";
                this.init();
            }
            if (tab.props.name == 'wait') {
                this.status = 0;
                this.init();
            }
            if (tab.props.name == 'finish') {
                this.status = 1;
                this.init();
            }
        },
        init() {
            console.log(this.merchantid)
            this.filterTableData = []
            this.axios.get(constant.getrecharge, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    mid: this.mid,
                    status: this.status,
                    keyword: this.keyword,
                    range:this.range,
                    merchantid: this.merchantid,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.code == 200) {
                  this.day = response.data.data.day*1
                  this.month = response.data.data.month*1
                    this.totalamount = response.data.data.totalamount
                    this.filterTableData = response.data.data.list
                    this.totalcount = response.data.data.totalcount * 1;
                    this.curpage = response.data.data.curpage * 1;
                } else {
                    this.$message.error("暂无数据");
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        Save() {
            console.log(this.rechargeid, this.serialnum)
            console.log(this.item)
            for (var i = 0; i < this.serialnum.length; i++) {
                if (this.serialnum[i]['index'] == '') {
                    this.$message.error("请填写交易流水号");
                    return false
                }
            }
            if (this.fileList.length == 0) {
                this.$message.error("请上传交易截图");
                return false
            }

            this.axios.get(constant.saverecharge, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    rechargeid: this.rechargeid,
                    filelist: this.fileList,
                    serialnum: this.serialnum,
                    amount: this.settleinfo.amount,
                    mid: this.item.mid
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.code == 200) {
                    this.$message.success("打款成功");
                    this.item.deal = 1
                    this.addsettle = false
                    this.item.account = response.data.account
                    console.log(this.item)
                } else {
                    this.$message.error("打款失败");
                }
            })
        },
        Cancel() {
            this.addsettle = false;
        },
        add() {
            this.addsettle = true;
        },
        formatDate(date) {
            var datetime = new Date(date * 1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2);
            var second = ("0" + datetime.getSeconds()).slice(-2);
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        console.log(this.ismerchant)
        if (this.ismerchant == 1) {
            this.activeName = ref('finish')
        }
        this.mid = Cookies.get("mid");
        this.axios.get(constant.allmerchant, {
            headers: {
                "content-type": "application/json"
            }
        }).then((response) => {
            console.log(response.data);
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>
<style scoped>
.tag-form-item {
    margin-bottom: 0;
}

.el-card__body {
    padding: 20px;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
